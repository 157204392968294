.placeHolder 
{
    display: flex;
    justify-content: center;
    align-items: center;
}
.banner 
{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 20vh;
    row-gap: 1rem;
}
.banner h3 
{
    width: 100%;
    text-align: center;
}
@media (max-width: 768px) {
    /* .banner{
        margin-top: ;
    } */
    .banner h3 
{
    font-size: 1rem;
}
.banner img 
{
    width: 70vw;
}
  }
  