@font-face {
    font-family: 'Outfit';
    src: url('../public/Outfit.ttf');
}
*
{
    font-family: 'Outfit';
    transition: .5s;
}
*::selection
{
    background-color: #533266;
    color: white;
}
body{
    margin: 0;
    padding: 0;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: black;
    color: white;
}
html
{
    margin:0;
    padding: 0;
}
.mainBG
{
    position: absolute;
    top: 0;
    left: 0;
    z-index: -2;
    width: 100vw;
    height: 100vh;
}
.overlayBG
{
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100vw;
    height: 100vh;
}