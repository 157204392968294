header {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.logoContainer {
  width: 80px;
  height: 80px;
  border-radius: 100%;
  /* border: 2px white solid; */
  display: flex;
  align-items: center;
  justify-content: center;
}
.logoContainer .logo {
  width: 100px;
  height: 100px;
}
.navBar {
  display: flex;
  flex-wrap: wrap;
  column-gap: 28px;
}
.navBar {
  padding: 0;
  margin: 0;
  cursor: pointer;
}
.navItemLogo {
  width: 30px;
}

@media (max-width: 768px) {
  .logoContainer {
    width: 50px;
    height: 50px;
  }
  .logoContainer .logo {
    width: 60px;
    height: 60px;
  }
  .navBar {
    display: flex;
    flex-wrap: wrap;
    column-gap: 15px;
  }
}
